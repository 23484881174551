import React, {useState, useEffect} from 'react'
import {Input, message, Spin,} from 'antd';
import {Button, Image, Toast,} from 'antd-mobile'
import {useNavigate, useLocation} from "react-router-dom";
import './index.less';
import BuildPay from '../../../components/Model/BuildPay';
import SignModal from '../../../components/Model/SignModal';
import Withdrawal from '../../../components/Model/Withdrawal';
import Http from "../../../utils/Http";
import {setToken} from "../../../store/app";
import {store} from "../../../store";

const id = require('./assets/icon_my_id@2x.png');
const zuanshi = require('./assets/icon_main_chongzhi_zuanhsi@2x.png');
const shendou = require('./assets/icon_main_wallet_shendou@2x.png');
const alipay = require('./assets/icon_main_zhifu_alipay@2x.png');
const wxpay = require('./assets/icon_main_zhifu_wchat@2x.png');
const black = require('./assets/icon_nav_black@2x.png');


const SignPage = (props) => {

    const buildRef = React.useRef();
    const signRef = React.useRef();
    const withdrawalRef = React.useRef();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [amount, setAmount] = useState("")
    const [yunzhanghuinfo, setYunzhanghuinfo] = useState("")

    const getYunzhanghuInfo = async () => {
        try {
            const res = await Http.postForm(
                '/api/v1/wechat_public/yunzhanghu',
                undefined,
                undefined,
                undefined,
                true,
            );
            if (res.status === 10000) {
                setYunzhanghuinfo(res.data)
            }
        } catch (e) {

        }
    }


    const submit = async () => {
        if (loading) {
            return
        }
        try {
            const res = await Http.postForm('/api/v1/wechat_public/yunzhanghuSign', {

            })
            if (res.status === 10000) {
                Toast.show("签约成功")
                setTimeout(function () {
                    window.location.href = '/'
                }, 1000)
            } else {
                Toast.show(res.message)

            }
        } catch (e) {
            Toast.show(e.message)

        } finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        getYunzhanghuInfo()
    }, [])

    return (
        <div className='sign-page'>
            <div className='sign-page-title'>
                签署协议
            </div>
            <div className='sign-page-content'>
                <iframe className='sign-page-content-frame' src={yunzhanghuinfo.url} frameborder="0"></iframe>
                <div className='sign-page-content-button'>
                    <Button style={{'--background-color': '#FFFFFF', '--border-color': '#e35b5a', textAlign: 'center', width: 230}} shape='block'
                            size='large'  danger onClick={() => {
                        submit()
                    }}>
                        <div style={{color: '#e35b5a', fontSize: 13}}>同意上述协议并签约</div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SignPage
