import qs from 'qs';
import axios from 'axios';
import { store } from '../store';
import Config from "../Config";
import {setToken} from "../store/app";

const service = axios.create({
  timeout: 30000, // request timeout  设置请求超时时间
});

const pending = new Map();

const addPending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join('&');
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pending.has(url)) {
        pending.set(url, cancel);
      }
    });
};

const removePending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join('&');
  if (!config.allowRepeated) {
    if (pending.has(url)) {
      const cancel = pending.get(url);
      cancel(url);
      pending.delete(url);
    }
  }
};

service.interceptors.request.use(
  async (config) => {
    const { App } = store.getState();
    if (App.token) {
      config.headers = {
        Authorization: 'Bearer ' + App.token,
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    } else {
      config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    }

    removePending(config);
    addPending(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    removePending(response);
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject({ status: 99888, repeatError: true, ...error });
    } else {
      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 403:
            Http.cancelHttp();
            store.dispatch(setToken(null))
            window.location.href="/"
            break;
          case 601:
            break;
          case 602:
            break;
          default:
            break;
        }
      }
      return Promise.reject(error);
    }
  }
);

const domain = Config.domain;
export default class Http {
  static getFullUrl(url, timeout) {
    return new Promise((resolve, reject) => {
      service
        .get(url, {
          timeout: timeout || 30000,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(`HTTP Response error ${url}:`, JSON.stringify(error));
          reject(error);
        });
    });
  }

  static postFullUrl(url, data, timeout) {
    return new Promise((resolve, reject) => {
      service
        .post(url, qs.stringify(data), {
          timeout: timeout || 30000,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(`HTTP Response error ${url}:`, JSON.stringify(error));
          reject(error);
        });
    });
  }

  static get(url, params, timeout) {
    return new Promise((resolve, reject) => {
      service
        .get(domain + url, {
          timeout: timeout || 30000,
          params: params,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(`HTTP Response error ${url}:`, JSON.stringify(error));
          reject(error);
        });
    });
  }

  static postForm(
    url,
    data,
    timeout = 15000,
    token = undefined,
    allowRepeated = true
  ) {
    return new Promise((resolve, reject) => {
      console.debug(`HTTP Required ${url}:`, data);
      service
        .post(domain + url, qs.stringify(data), {
          timeout: timeout,
          allowRepeated: allowRepeated,
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          if (error?.status !== 99888) {
            console.debug(`HTTP Response error ${url}:`, JSON.stringify(error));
          }
          reject(error);
        });
    });
  }

  static cancelHttp() {
    for (const [url, cancel] of pending) {
      cancel(url);
    }
    pending.clear();
  }
}
