import React, { useState, useEffect } from "react";
import "./index.less";
// import { Input, message, Spin, Modal } from "antd";
import { Button, Image } from "antd-mobile";
import { useNavigate } from "react-router-dom";
const succeed = require("./assets/ok.png");

const ChargeWin = (props) => {
  const navigate = useNavigate();
  return (
    <div className="chargeWin">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 100,
        }}
      >
        <Image
          style={{ marginBottom: 10 }}
          src={succeed}
          width={80}
          height={80}
        />
        <div style={{ fontSize: 17, color: "#333" }}>支付成功</div>
        <Button
          style={{
            "--background-color": "#F03550",
            borderRadius: 80,
            height: 45,
            width: "80%",
            marginTop: 60,
            color: "#fff",
            fontWeight: 600,
          }}
          shape="rounded"
          danger
          onClick={() => {
            navigate("/charge");
          }}
        >
          确 定
        </Button>
      </div>
    </div>
  );
};
export default ChargeWin;
