import React, { useState, useEffect } from 'react'
import { Toast, Mask,DatePickerView } from 'antd-mobile'
import './index.css'

const now = new Date()
const min = new Date('2022-05');

const DatePicker = React.forwardRef((props, ref) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false)
    const [value, setValue] = useState(now);
    const {date} = props


    const show = () => {
        setVisible(true);
    };

    React.useImperativeHandle(ref, () => {
        return {
            show: show,
        };
    });


    return (
        <Mask visible={visible} onMaskClick={() => setVisible(false)}>
            <div className='date'>
            <div className='dateModel'>
            <div className='dateBtn'>
                <div style={{fontSize:15,color:'#979797',fontWeight:600}} onClick={()=>{setVisible(false)}}>取消</div>
                <div style={{fontSize:15,color:'#F03350',fontWeight:600}} onClick={()=>{props.onChange(value);setVisible(false)}}>确认</div>
            </div>
            <DatePickerView defaultValue={now} precision='month' min={min} max={now} onChange={val => setValue(val)} />
            </div>
            </div>
        </Mask>
    )
})

export default DatePicker
