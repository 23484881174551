import "./App.css";
import Charge from "./page/Charge";
import GetCode from "./page/Login/GetCode";
import QrCode from "./page/Login/QrCode";
import { Route, Routes } from "react-router-dom";
import MobileLogin from "./page/Login/MobileLogin";
import Withdraw from "./page/Login/Withdraw";
import WithdrawalList from "./page/WithdrawalList";
import WithdrawalDetail from "./page/WithdrawalDetail";
import SignPage from "./page/Login/SignPage";
import AccountList from "./page/AccountList";
import Chargewin from "./page/Charge/chargeWin";
import { useEffect, useState } from "react";
import UAParser from "ua-parser-js";
import Http from "./utils/Http";

const App = () => {
  const [openID, setOpenID] = useState(null);
  const [token, setToken] = useState(null);

  const wechatOauthCheck = async () => {
    // const ua = new UAParser()
    // if (ua.getBrowser().name !== "WeChat") {
    //     return
    // }
    //
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.open_id) {
      setOpenID(params.open_id);
      return;
    }
    // const res = await Http.postForm('/api/v1/wechat_public/getAuthUrl', {
    //     redirect_uri: window.location.href
    // })
    // if (res.status === 10000) {
    //     window.location.href = res.data
    // }
  };

  useEffect(() => {
    wechatOauthCheck();
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<QrCode />} />
        <Route path="/qrcode" element={<QrCode />} />
        <Route path="/charge" element={<Charge openID={openID} />} />
        <Route path="/signpage" element={<SignPage />} />
        <Route path="/mobile" element={<MobileLogin />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/withdrawalList" element={<WithdrawalList />} />
        <Route path="/withdrawalDetail" element={<WithdrawalDetail />} />
        <Route path="/accountlist" element={<AccountList />} />
        <Route path="/chargewin" element={<Chargewin />} />
      </Routes>
    </div>
  );
};

export default App;
