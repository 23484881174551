import React, {useState, useEffect} from 'react'
import {Input, message,} from 'antd';
import axios from 'axios';
import {Button, Toast, Image, Mask} from 'antd-mobile'
import './index.css'


const Withdrawal = React.forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)


    const show = () => {
        setVisible(true);
    };

    React.useImperativeHandle(ref, () => {
        return {
            show: show,
        };
    });


    return (
        <Mask visible={visible} onMaskClick={() => setVisible(false)}>
            <div className='walMask'>
                <div style={{
                    display: 'flex',
                    flex: 2,
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    flexDirection: 'column',
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                    <div style={{fontSize: 18, color: '#050303', fontWeight: 600}}>提现确认</div>
                    <div style={{
                        fontSize: 18,
                        color: '#979797',
                        fontWeight: 400
                    }}>提现金额 {props.amount}<br/>
                        实际到账 {(props.amount * (1-Number(props.tixian.data))).toFixed(2)}
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 276,
                    marginBottom: -9
                }}>
                    <Button style={{height: 50, '--border-width': 0, borderRadius: 8}} block onClick={() => {
                        setVisible(false)
                    }}>
                        <div style={{color: '#979797', fontSize: 16}}>取消</div>
                    </Button>
                    <Button style={{height: 50, '--border-width': 0, borderRadius: 8}} block onClick={() => {
                        setVisible(false)
                        props.onSubmit && props.onSubmit()
                    }}>
                        <div style={{color: '#F03550', fontSize: 16}}>确定</div>
                    </Button>
                </div>
            </div>
        </Mask>
    )
})

export default Withdrawal
