
import appReducer from "./app";
import {persistStore, persistReducer} from 'redux-persist';
import {createStore, combineReducers} from 'redux';
import localStorage from "redux-persist/es/storage";

const persistConfig = {
    key: 'root',
    storage: localStorage,
};

const rootReducer = combineReducers({
    App: appReducer,
});
const perReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(perReducer);
export const persistor = persistStore(store);
