import React, { useState, useEffect } from "react";
import "./index.less";
import { Input, message, Spin, Modal } from "antd";
import { Button, Toast, Image, Mask } from "antd-mobile";
import Http from "../../utils/Http";
import UAParser from "ua-parser-js";

const alipay = require("./assets/icon_main_zhifu_alipay@2x.png");
const wxpay = require("./assets/icon_main_zhifu_wchat@2x.png");
const agree = require("./assets/login_agreement_agree@2x.png");
const disAgree = require("./assets/login_agreement_disagree@2x.png");
const moren = require("./assets/icon_pc_rwcharge@2x.png");

const Charge = (props) => {
  const [text, setText] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [human, setHuman] = useState(null);
  const [payId, setPayId] = useState(0);
  const [value, setValue] = useState("");
  const [sand, setSand] = useState("");
  const [h5status, setH5status] = useState(1);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [aLoading, setALoading] = useState(false);
  const [agreement, setAgreement] = useState("");
  //提示框
  const [isModalOpen, setIsModalOpen] = useState(true);

  const getProduct = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await Http.postForm(
        "/api/v1/wechat_public/wechatPublicRechargePackageList"
      );
      if (res?.status === 10000) {
        setData(res.data);
      } else {
        message.warning(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const [payList, setpayList] = useState([]);
  const getDatalist = async () => {
    try {
      const res = await Http.postForm("/api/v1/pay/payH5List");
      if (res?.status === 10000) {
        setpayList(res.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };

  const handleCheck = async () => {
    if (checkLoading) {
      return;
    }
    setCheckLoading(true);
    try {
      const res = await Http.postForm("/api/v1/wechat_public/getUserByUid", {
        uid: text,
      });
      if (res?.status === 10000) {
        setHuman(res.data);
      } else {
        message.warning(res.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setCheckLoading(false);
    }
  };
  const charge = async () => {
    if (loading) {
      return;
    }
    if (!human) {
      message.warning("没有输入充值账号");
      return;
    }
    if (payId === 0) {
      message.warning("请选择支付方式");
      return;
    }
    // if (payId === 2 && !props.openID) {
    //   message.warning("请在微信中打开充值页面");
    //   return;
    // }
    const ua = new UAParser();
    if (
      sand === "支付宝" &&
      (ua.getBrowser().name === "WeChat" || ua.getBrowser().name === "QQ")
    ) {
      Toast.show("请用浏览器打开再选择支付宝支付");
      return;
    }
    getDatalist();

    try {
      setLoading(true);
      const url =
        index === -1
          ? "/api/v1/wechat_public/recharge"
          : "/api/v1/wechat_public/rechargePackageBuy";
      const res = await Http.postForm(url, {
        uid: human.uid,
        package_id: data[index]?.id,
        pay_type: payId,
        get_amount: value,
        open_id: props.openID,
      });
      if (res.status === 10000) {
        if (sand === "支付宝" || payId === 3 || payId === 4) {
          window.location.href = res.data.pay_url;
        } else if (sand === "微信" && (payId === 1 || payId === 2)) {
          // 微信支付;
          const options = JSON.parse(res.data.pay_url);
          console.log("options", {
            appId: options.Appid,
            timeStamp: options.TimeStamp,
            nonceStr: options.NonceStr,
            package: options.Package,
            signType: options.SignType,
            paySign: options.PaySign,
          });
          window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
              appId: options.Appid,
              timeStamp: options.TimeStamp,
              nonceStr: options.NonceStr,
              package: options.Package,
              signType: options.SignType,
              paySign: options.PaySign,
            },
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                message.success("充值完成");
              }
            }
          );
        }
      } else {
        message.warning(res.message);
      }
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };
  const getAgreementId = async () => {
    if (aLoading) {
      return;
    }
    setALoading(true);
    try {
      const res = await Http.postForm("/api/v1/common/config");
      console.log(res.data.business_agreement_recharge_agreement);
      if (res?.status === 10000) {
        await getAgreementContent(
          res.data.business_agreement_recharge_agreement
        );
      } else {
        message.warning(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setALoading(false);
    }
  };
  const getAgreementContent = async (id) => {
    try {
      const res = await Http.postForm("/api/v1/article/detail?id=" + id);
      console.log(res);
      if (res?.status === 10000) {
        setAgreement(res.data.content);
      } else {
        message.warning(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      // setALoading(false);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getProduct();
    getDatalist();
  }, []);

  return (
    <div className="recharge">
      <div className="header">
        <div className="header-left">
          <Image
            src={human?.avatar ?? moren}
            width={50}
            height={50}
            style={{ borderRadius: 35, marginRight: 8 }}
          />
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Input
              style={{ paddingLeft: 0, flex: 1 }}
              bordered={false}
              placeholder="请输入UID或绑定手机号"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
            {human ? (
              <div style={{ color: "#979797", fontSize: 13 }}>
                {human?.nickname}
              </div>
            ) : null}
          </div>
        </div>
        <Button
          style={{ "--background-color": "#F03550", "--border-width": 0 }}
          shape="rounded"
          danger
          onClick={() => {
            if (text.length === 0) {
              Toast.show("请输入账号UID或手机号");
            } else {
              handleCheck();
            }
          }}
        >
          <div style={{ color: "#ffffff", fontSize: 13 }}>
            {checkLoading && <Spin />} 检测账号
          </div>
        </Button>
      </div>
      <div className="center">
        <div className="center-top">
          <div className="center-top-header">
            <div className="center-top-header-left">充值声豆</div>
          </div>
          <div className="center-top-list">
            {data?.map((item, i) => {
              return (
                <div
                  key={item?.id}
                  style={{ borderColor: index === i ? "#F03350" : "#979797" }}
                  className="center-top-list-item"
                  onClick={() => {
                    setIndex(i);
                    setValue("");
                  }}
                >
                  <Image
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 5,
                      marginLeft: 12,
                    }}
                    src={item?.get_asset_info?.icon}
                  />
                  <div className="center-top-item-price">
                    <div className="center-top-item-price-left">
                      {item?.get_amount}
                    </div>
                    <div className="center-top-item-price-center">/</div>
                    <div className="center-top-item-price-right">
                      ¥ {item?.pay_amount}
                    </div>
                  </div>
                </div>
              );
            })}
            <Input
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setIndex(-1);
              }}
              style={{ borderRadius: 8, marginBottom: 6, height: 44 }}
              prefix={
                <Image
                  style={{ width: 24, height: 24, marginRight: 5 }}
                  src={data[0]?.get_asset_info?.icon}
                />
              }
              placeholder="请输入要充值的声豆数量"
            />
            <div
              style={{
                marginBottom: 16,
                color: "#3B82F6",
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              请填写大于 100 的整数
            </div>
          </div>
        </div>
        <div className="pay-way">
          <div className="pay-way-text">支付方式</div>
          {payList?.map(
            (item, i) =>
              item.name == "微信"
                ? props.openID &&
                  item.h5_status === 1 && (
                    <div
                      className="pay-way-item"
                      id={"wechat"}
                      onClick={() => {
                        setPayId(item.id);
                        setSand(item.name);
                        setH5status(item.h5_status);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <Image
                          style={{ marginRight: 8 }}
                          src={item.icon}
                          width={24}
                          height={24}
                        />
                        <span>{item.name}</span>
                      </div>
                      <Image
                        src={payId === item.id ? agree : disAgree}
                        width={24}
                        height={24}
                      />
                    </div>
                  )
                : item.h5_status === 1 && (
                    <div
                      className="pay-way-item"
                      id={"alipay"}
                      onClick={() => {
                        setPayId(item.id);
                        setSand(item.name);
                        setH5status(item.h5_status);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <Image
                          style={{ marginRight: 8 }}
                          src={item.icon}
                          width={24}
                          height={24}
                        />
                        <span>{item.name}</span>
                      </div>
                      <Image
                        src={payId === item.id ? agree : disAgree}
                        width={24}
                        height={24}
                      />
                    </div>
                  )
            // <div>{item.name}</div>
          )}

          {/* {props.openID && (
            <div
              className="pay-way-item"
              id={"wechat"}
              onClick={() => {
                setPayId(2);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Image
                  style={{ marginRight: 8 }}
                  src={wxpay}
                  width={24}
                  height={24}
                />
                <span>微信</span>
              </div>
              <Image
                src={payId === 2 ? agree : disAgree}
                width={24}
                height={24}
              />
            </div>
          )} */}
        </div>
        <Button
          onClick={charge}
          style={{
            "--background-color": "#F03550",
            borderRadius: 8,
            height: 45,
          }}
          block
          size="large"
          loadingText="充值"
        >
          {loading ? (
            <Spin size={12} />
          ) : (
            <div style={{ color: "#ffffff", fontSize: 14, fontWeight: 600 }}>
              充值
            </div>
          )}
        </Button>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div style={{ color: "#979797", fontSize: 12 }}>
            温馨提示:{" "}
            <span style={{ color: "#F03550", fontSize: 14 }}>
              请勿为陌生人员代充，谨防电信诈骗！！！
            </span>
          </div>
          <div
            style={{
              flexWrap: "wrap",
              textAlign: "left",
              color: "#979797",
              fontSize: 12,
            }}
          >
            1、充值前请仔细阅读
            <span
              style={{ color: "#3B82F6", fontSize: 12 }}
              onClick={async () => {
                await getAgreementId();
                setIsModalVisible(true);
              }}
            >
              《深恋充值协议》
            </span>
            充值成功即代表您同意此协议的内容。
          </div>
          <span style={{ color: "#979797", fontSize: 12 }}>
            2、充值成功后无法退款，不可提现
          </span>
          <span style={{ color: "#979797", fontSize: 12 }}>
            3、未成年人需在监护人陪同或许可下操作
          </span>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        mask
        maskClosable
        footer={null}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: agreement }}></div>
      </Modal>
      {aLoading ? (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: 120,
              height: 120,
              backgroundColor: "rgba(0,0,0,0.45)",
              borderRadius: 24,
            }}
          >
            <Spin />
            <span
              style={{
                marginTop: 10,
                fontSize: 14,
                fontWeight: "600",
                color: "#FFF",
              }}
            >
              加载中...
            </span>
          </div>
        </div>
      ) : null}
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        okText={"知道了"}
        width={400}
        closeIcon={" "}
        footer={
          [
            <div className="okText" onClick={handleOk}>
              我知道了
            </div>,
          ] // 设置footer为空，去掉 取消 确定默认按钮
        }
      >
        <div className="title_text">郑重警告</div>
        <div className="title_text2">
          请不要相信T某语音等第三方平台人员诱导充值！
        </div>
      </Modal>
    </div>
  );
};

export default Charge;
