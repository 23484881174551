import React, {useState, useEffect} from 'react'
import {Input, message,} from 'antd';
import {Button, Image,} from 'antd-mobile'
import {useNavigate} from "react-router-dom";
import Http from '../../../utils/Http';
import './index.less';
import {useSelector} from "react-redux";


const GetCode = () => {
    const [tel, setTel] = useState([])
    const [code, setCode] = useState([]);
    const [loading, setLoading] = useState(false);
    const [key, setkey] = useState([]);
    const token = useSelector(state => state.App.token)
    const [image, setImage] = useState([]);
    const navigate = useNavigate();

    const getCode = async () => {
        if (loading) {
            return
        }
        setLoading(true);
        try {
            const res = await Http.postForm('/api/v1/auth/captchaImage');
            if (res?.status === 10000) {
                setkey(res.data.IdKey)
                setImage(res.data.img)
            } else {
                message.warning(res.data.message)
            }
        } catch (error) {
            message.error('网络错误，请检查网络设置');
        } finally {
            setLoading(false);
        }
    }
    const handleGetCode = async () => {
        if (loading) {
            return
        }
        try {
            const res = await Http.postForm('/api/v1/sms/sendCommonSMS', {
                mobile: tel,
                id_key: key,
                validate_code: code,
            })
            if (res.status === 10000) {
                navigate('/mobile', {state: {tel: tel, code: code}})
            } else {
                message.warning(res.message)
            }
        } catch (e) {
            message.warning(e.message)
        } finally {
            setLoading(false);
            getCode()
        }

    }

    useEffect(() => {
        if (token) {
            navigate('/withdraw')
            return
        }
        getCode();
    }, [token])

    return (
        <div className='login-page'>
            <div className='login-text'>账号登录</div>
            <Input placeholder="请输入手机号码" type="number" maxLength={11}
                   style={{fontSize: 14, height: 48, borderRadius: 8, boxShadow: 'none', borderColor: '#d9d9d9'}}
                   value={tel} onChange={(e) => {
                setTel(e.target.value)
            }}/>
            <div className='code'>
                <Input
                    placeholder="请输入图形验证码"
                    type="number"
                    maxLength={4}
                    style={{
                        fontSize: 14,
                        height: 48,
                        borderRadius: 8,
                        marginRight: 8,
                        boxShadow: 'none',
                        borderColor: '#d9d9d9'
                    }} 
                    value={code} onChange={(e) => {
                        setCode(e.target.value)
                    }}
                />
                <Image onClick={getCode} src={image} style={{borderRadius: 8}} width={112} height={48}/>
            </div>
            <Button
                disabled={tel.length !== 0 && code.length !== 0 ? false : true}
                style={{
                    height: 48,
                    borderRadius: 8,
                    backgroundColor: tel.length !== 0 && code.length !== 0 ? '#F03350' : '#B0B0B0',
                    marginTop: 22
                }}
                block
                onClick={() => {
                    handleGetCode()
                }}>
                <div
                    style={{
                        color: '#FFFFFF',
                        fontSize: 14,
                        fontWeight: 600
                    }}>
                    获取验证码
                </div>
            </Button>
            <div className='bottom'>
                登录代表您已同意<span style={{fontSize: 13, color: '#F03550'}}>《深恋用户协议》</span>
            </div>
        </div>
    )
}

export default GetCode
