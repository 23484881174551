import React, {useState, useEffect} from 'react'
import {Input, message,} from 'antd';
import {Button, Image,} from 'antd-mobile'
import {useNavigate} from "react-router-dom";
import Http from '../../../utils/Http';
import './index.less';
import {useSelector} from "react-redux";
import {store} from "../../../store";
import {setToken} from "../../../store/app";


const GetCode = () => {
    const [tel, setTel] = useState([])
    const [code, setCode] = useState([]);
    const [loading, setLoading] = useState(false);
    const [key, setkey] = useState([]);
    const token = useSelector(state => state.App.token)
    const [image, setImage] = useState([]);
    const navigate = useNavigate();

    const handleGetCode = async () => {
        // 获取链接中的loginname和password
        let queryString =  window.location.href.split('?')[1]
        let loginname = queryString.split('&')[0].split('=')[1]
        let password = queryString.split('&')[1].split('=')[1]

        if (loading) {
            return
        }
        try {
            const res = await Http.postForm('/api/v1/wechat_public/loginByGuildPassword', {
                loginname: loginname,
                password: password,
            })
            if (res.status === 10000) {
                store.dispatch(setToken(res.data.token))
                navigate('/withdraw')
            } else {
                message.warning(res.message)
            }
        } catch (e) {
            message.warning(e.message)
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        if (token) {
            navigate('/withdraw')
            return
        }

        handleGetCode()
    }, [token])

    return (
        <div className='login-page2'>
            <div className='login-text'>验证中...</div>
        </div>
    )
}

export default GetCode
